.companies-loader__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0rem;
}

.companies-loader-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid green;
  background: #fcfcfc;
  border: 2px solid #efefef;
  border-radius: 20px 4px;
  width: 135px;
  height: 40px;
}

.companies-loader__spinner {
  height: 35px;
  width: 35px;
}

.companies-loader__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  color: #1a1d1f;
}
